import { memo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { numberFormat } from 'utils'
import { parseHtmlIntl } from 'ts-utils'
import { 
    DELETE_SURVEY_ANALYSIS_USERS_INDIVIDUAL,
    DELETE_SURVEY_ANALYSIS_USERS_ALL_SOCKET,
    DELETE_SURVEY_ANALYSIS_USERS_ALL,
} from 'gql/survey_analysis_users'

import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@material-ui/core'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import useCheckSocketFile from 'hooks/useCheckSocketFile'
import { Open, OnClose } from './RemoveFileButton'
import { Props as ParentProps } from './RemoveUserButton'
import { FileUploadContext } from './Component'

interface Props extends UsePrevStateProps, ParentProps {
    open: Open;
    onClose: OnClose;
}

type ConditionContentProps = {
    step: ParentProps['where']['step'];
    date: ParentProps['where']['date'];
    search: ParentProps['search']
}

// 유저 삭제
function getUserText(type: Open, f: any, checked: ParentProps['checked']) {
    if (type === 'all') {
        return {
            title: f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.title'}),
            subtitle: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.all.subtitle'})),
            content: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.content'}))
        }
    } 

    if (type === 'part') {
        return {
            title: f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.title'}),
            subtitle: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.part.subtitle'}, {count: numberFormat(checked.length)})),
            content: parseHtmlIntl(f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.content'}))
        }
    } 

    return { title: '', subtitle: '', content: '' }
}

export const ConditionContent = (props: ConditionContentProps) => {
    const { date, step, search } = props

    const { formatMessage: f } = useIntl()

    return (
        <div style={{padding: 20}}>
            <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                    {f({id: 'component.Survey.Create.Analysis.Rows.RemoveUserDialog.season'})}
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    {date ? date.replace('~', ' ~ ') : f({id: 'styled.Survey.Analysis.DateButton.popover.0'})}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                    {f({id: 'component.Survey.Create.Analysis.Buttons.step.name'})}
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    {f({id: `component.Survey.Create.Analysis.Buttons.step.item.${step}`})}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} style={{fontWeight: 900}}>
                    {f({id: 'component.Survey.Create.Analysis.Rows.RemoveUserDialog.search'})}
                </Grid>
                <Grid item xs={8} sm={8} md={8}>{search}</Grid>
            </Grid>
        </div>
    )
}

function RemoveUserDialogComponent(props: Props) {
    const { open, where, checked, search, startpoint, isRemoveLastPageAllData, onChangeStartpoint, onRefetch, onClose, usePrevState } = props

    const { refetch: refetchFileUploadSize } = useContext(FileUploadContext)

    const dispatch = useDispatch()

    const prevOpen = usePrevState(open)

    const { formatMessage: f } = useIntl()

    const params = useParams<{ survey_no: string }>()
    const survey_no = Number(params.survey_no)

    // 전체 데이터 삭제는 소켓통신으로 삭제하고 다되면 s3에서 파일을 올려서 알림을 준다
    const [ getSocketFile ] = useCheckSocketFile({ maxCheck: 50, intervalTime: 1000 })

    function success() {
        refetchFileUploadSize()
        
        onRefetch()

        if (isRemoveLastPageAllData) {
            onChangeStartpoint(startpoint - 1)
        }
   
        onClose()
        dispatch({ type: BACKDROPHIDE })
    }

    function fail() {
        onClose()
        dispatch({ type: BACKDROPHIDE })
        dispatch({ type: ERRORPAGESHOW })
    }

    const [ deleteUsersIndividual ] = useMutation(DELETE_SURVEY_ANALYSIS_USERS_INDIVIDUAL, {
        onCompleted: () => {
            success()
        },
        onError: () => {
            fail()
        }
    })

    /*
    const [ deleteUsersAll ] = useMutation(DELETE_SURVEY_ANALYSIS_USERS_ALL_SOCKET, {
        onCompleted: (data) => {
            getSocketFile(data.deleteSurveyAnalysisUsersAllSocket)
            .then(() => {
                success()
            })
            .catch(() => {
                // 리페치 시도
                success()
            })
        },
        onError: () => {
            fail()
        }
    })
    */

    const [ deleteUsersAll ] = useMutation(DELETE_SURVEY_ANALYSIS_USERS_ALL, {
        onCompleted: () => {
            success()
        },
        onError: () => {
            fail()
        }
    })

    function handleDelete() {
        if (open === 'all') {
            dispatch({ type: BACKDROPSHOW })

            let newWhere = {...where}
            if (typeof newWhere.step !== undefined) newWhere.step = Number(newWhere.step)

            deleteUsersAll({ variables: { survey_no, input: newWhere }})
            return
        }

        if (open === 'part') {
            dispatch({ type: BACKDROPSHOW })
            deleteUsersIndividual({ variables: { survey_no, ids: checked }})
            return
        }
    }

    const { title, subtitle, content } = getUserText((open || prevOpen), f, checked)

    return (
        <Dialog open={Boolean(open)} fullWidth={true} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>
                    {subtitle}
                </Typography>
                <ConditionContent {...where} search={search}/>
                <Typography>
                    {content}
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleDelete}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.0'})}</Typography>
                </Button>
                <Button onClick={onClose}>
                    <Typography variant="button">{f({id: 'component.Survey.Create.Analysis.Rows.RemoveModal.button.1'})}</Typography>
                </Button>
            </DialogActions>
        </Dialog> 
    )
}

export default memo(withPrevState(RemoveUserDialogComponent))