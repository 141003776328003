/**
 * 아이템 파일업로드 답변
 */

import { memo } from 'react'
import { getMimePicture } from 'utils/survey'
import { handleDisabledRightClick } from 'ts-utils'
import { downloadFile } from 'utils'
import { SurveyAnalysisRowsProps } from 'gql/survey_analysis_rows' 
import makeStyles from '@material-ui/core/styles/makeStyles'
import GetAppIcon from '@material-ui/icons/GetApp'
import withSurveyPopoverImageNew, { OnPopoverImageOpen } from 'hoc/Survey/PopoverImageNew'
import { getFilename } from '../Summary/DataItem'
import { useStyles as useParentStyles } from './Item' 

type QuestionFile = {[a: string]: SurveyAnalysisRowsProps['files']}

interface Props {
    classShort: string;
    classBox: string;
    classComment: string;
    keyValue: string;
    questionFile: QuestionFile;
    commentValue?: string;
    fileQuestionTitle: { [key: number]: string };
    onPopoverImageOpen: OnPopoverImageOpen;
}

const useStyles = makeStyles({
    fileBox: {
		display: 'flex', 
		alignItems: 'flex-end', 
		justifyContent: 'space-between',
		borderRadius: 3, 
		padding: 5,
	}
})

function ItemValueComponent(props: Props) {
    const { classShort, classBox, classComment, keyValue, questionFile, commentValue, fileQuestionTitle, onPopoverImageOpen } = props

    const classes = useStyles()

    const classesParent = useParentStyles()
    
    let fullSrc = ''
    const fileObj = questionFile[keyValue.replace('Q', '')]

    const { src, filename, survey_analysis_users_no, survey_question_no } = fileObj ? fileObj[0] : { src: '', filename: '', survey_analysis_users_no: '', survey_question_no: 0 }
    fullSrc = src ? `${process.env.REACT_APP_LISTO_CF}${src}` : ''
    
    const handleClick = async() => {
        const title = fileQuestionTitle[survey_question_no] 
        
        const newTitle = title ? `${getFilename({ title })}-${survey_analysis_users_no}-${filename}` : `${survey_analysis_users_no}-${filename}`

        const blob = await fetch(fullSrc).then(response => {
            if (response.status !== 200) return null
            return response.blob()
        })
    
        downloadFile({ filename: newTitle, data: blob })

    }

    const mime = getMimePicture(fullSrc)


    if (!fullSrc) return null
    
    return (
        <span className={classBox}>		
            <span className={classes.fileBox}>
                {
                    mime === 'image' ? (
                        <img src={fullSrc} style={{ width: 50, height: 50, borderRadius: 3, cursor: 'pointer' }} loading="lazy" onClick={() => onPopoverImageOpen(fullSrc)} onContextMenu={handleDisabledRightClick} alt="listovey" />
                    ) : (
                        <span className={classShort}>{filename}</span>
                    )
                }
                <GetAppIcon color="action" style={{ cursor: 'pointer' }} onClick={handleClick}/>
            </span>
            {
                commentValue && <span className={`${classComment} ${classShort}`}>{commentValue}</span>
            }
        </span>
    )
}

export default withSurveyPopoverImageNew(memo(ItemValueComponent))