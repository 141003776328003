import { memo, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'
import { _05, GET_SURVEY_ANALYSIS_SUMMARY_COMMENTS } from 'gql/survey_analysis_summary'
import CommentsStyled from 'styled/Survey/Analysis/SummaryComment'
import CommentsItemStyled from 'styled/Survey/Analysis/SummaryCommentItem'
import { getKey } from 'hoc/Survey/Summary'

interface Props {
    survey_no: number;
    id: string; 
    comments: _05['comments'];
    commentLen: number;
    isCommentLen?: boolean;
}

function DataItemCommentsComponent(props: Props) {
    const { survey_no, id, comments, commentLen } = props

    const [ rows, setRows ] = useState<_05['comments']>(comments)

    const [ getData ] = useLazyQuery(GET_SURVEY_ANALYSIS_SUMMARY_COMMENTS, {
        onCompleted: (data) => {
            setRows(prevState => ([
                ...prevState,
                ...data.surveyAnalysisSummaryComments
            ]))
        }
    })

    const len = rows.length

    const handleFetch = () => {
        getData({
            variables: {
                survey_no, 
                key: getKey(survey_no), 
                id: String(id), 
                offset: len
            }
        })
    }

    useEffect(() => {
        setRows(comments)
    }, [comments])

    return ( 
        <> 
        <CommentsStyled className="_09" id={`scrollableDiv-${id}`}>
            <InfiniteScroll
                dataLength={len}
                next={handleFetch}
                hasMore={commentLen > len}
                loader={<div>...Loading</div>}
                scrollableTarget={`scrollableDiv-${id}`}
            >
            {
                rows.map((d, j) => {
                    return <CommentsItemStyled key={d.idd} idd={d.idd} comment={d.comment} wdate={d.wdate}/>
                })
            }
            </InfiniteScroll>
        </CommentsStyled>
        </>
    )
}

export default memo(DataItemCommentsComponent)