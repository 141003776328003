/**
 * 현재 페이지 내에서 검색
 */
import { memo, useState, ChangeEvent, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Paper, InputBase, IconButton } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SearchIcon from '@material-ui/icons/Search'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

interface Props {
    value: string;
    onChange: (val: string) => void;
}

const useStyles = makeStyles(theme => ({
    paper: {
        height: 30,
        padding: '2px 8px',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: 260,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    input: {
        flex: 1,
        fontSize: 13
    },
    iconButton: {
        padding: 0,
        paddingRight: 2
    }
}))

let timer:  ReturnType<typeof setTimeout>
let timer2:  ReturnType<typeof setTimeout>

function SearchComponent(props: Props) {
    const { value, onChange } = props 
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const ref = useRef<null | HTMLInputElement>(null)

    const [ newValue, setNewValue ] = useState(value)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value)
        clearTimeout(timer)
        timer = setTimeout(() => {
            onChange(event.target.value)
        }, 300)
    }

    const handleChangeNull = () => {
        setNewValue('')
        if (ref) ref.current?.focus()

        clearTimeout(timer2)
        timer2 = setTimeout(() => {
            onChange('')
        }, 300)
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
            clearTimeout(timer2)
        }
    }, [])

    return (
        <Paper variant="outlined" className={classes.paper}>
            <InputBase
                inputRef={ref}
                className={classes.input}
                placeholder={f({id: 'component.Survey.Create.Analysis.Buttons.placeholder'})}
                inputProps={{ 'aria-label': f({id: 'component.Survey.Create.Analysis.Buttons.placeholder'}) }}
                value={newValue}
                onChange={handleChange}
            />
            {
                newValue !== '' && (
                    <IconButton type="submit" size="small" className={classes.iconButton} aria-label="search" onClick={handleChangeNull} >
                        <HighlightOffRoundedIcon fontSize='small'/>
                    </IconButton>
                )
            }
            <SearchIcon color="action" fontSize='small'/>
        </Paper>
    )
}

export default memo(SearchComponent)