import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import oc from 'open-color'
import { getKeyOfArray } from 'ts-utils'
import { CHANGE as CHANGEPERSONAL } from 'reducer/analysis/rows/personal'
import { Step } from 'gql/survey_analysis_summary'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import LaunchIcon from '@material-ui/icons/Launch'
import withSurveyPopoverImageNew from 'hoc/Survey/PopoverImageNew'
import ItemValueFileComponent from './ItemValueFile'
import { isMultiChoice, isRankChoice, isRankDChoice } from './TableHead'
import { isChoiceComment, isRankComment, isImageQuestion } from './Items'

/* import FileDownloadButtonComponent from './FileDownloadButton' */

export const useStyles = makeStyles(theme => ({
	tableCell: {
        borderRight: `1px solid ${oc.gray[3]}`,
        '&:last-child': {
            borderRight: 'none'
		}
	},
	checkbox: {
		position: 'sticky',
		zIndex: 1,
		left: 0,
		background: 'white',
		paddingLeft: 10,
		paddingRight: 10,
		cursor: 'pointer'
    },
    id: ({ selectedLaunch }) => {
		return {
			position: 'sticky',
			zIndex: 1,
			left: 45,
			background: 'white',
			borderRight: `1px dashed ${oc.gray[5]}`,
			'& .launch': {
				opacity: selectedLaunch ? 1 : .3,
			}
		}
    },
	box: {
		display: 'block',
		width: '100%',
		background: oc.indigo[0], 
		borderRadius: 3, 
		padding: 5,
		'& + &': {
			marginTop: 5
		}
	},
	boxFirst: {
		display: 'block',
		width: '90%',
		background: oc.indigo[0], 
		borderRadius: 3, 
		padding: 5,
		'& + &': {
			marginTop: 5
		}
	},
	imgTextBox: {
		display: 'flex',
		alignItems: 'center',
		gap: 5
	},
	textShort: (props) => {
		const { expand } = props

		if (expand) {
			return {
				width: 205,
				fontSize: 13,
				lineHeight: '140%',
				wordBreak: 'break-all'
			}
		}

		return {
			display: 'block',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			width: 205,
			fontSize: 13,
			lineHeight: '120%'
		}
	},
	textShortFirst: (props) => {
		const { expand } = props

		if (expand) {
			return {
				width: 180,
				fontSize: 13,
				lineHeight: '120%',
				wordBreak: 'break-all'
			}
		}

		return {
			display: 'block',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			width: 180,
			fontSize: 13,
			lineHeight: '120%'
		}
	},
	img: {
		display: 'block',
		width: 26, 
		height: 26, 
		borderRadius: 3
	},
	comment: (props) => {
		const { expand } = props

		return {
			display: 'block',
			width: expand ? '100%' : 205,
			borderTop: `1px dashed ${theme.palette.grey[400]}`,
			marginTop: 5,
			paddingTop: 8,
		}
	},
	commentFirst: (props) => {
		const { expand } = props

		return {
			display: 'block',
			width: expand ? '100%' : 180,
			borderTop: `1px dashed ${theme.palette.grey[400]}`,
			marginTop: 5,
			paddingTop: 8,
		}
	},
	fileBox: {
		display: 'flex', 
		alignItems: 'flex-end', 
		justifyContent: 'space-between'
	},
	launch: ({ selectedLaunch }) => {
		return {
			position: 'absolute',
			right: 0,
			top: 0,
			background: selectedLaunch ? 'rgba(130, 130, 130, .1)' : 'rgba(150, 150, 150, .1)',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			paddingLeft: 5,
			paddingRight: 5,
			opacity: 0,
			transition: 'all .1s',
			'&:hover': {
				opacity: 1
			}
		}
		
	}
}))

const getAnswer = (answer, choiceCommentIndex) => {
	const keys = Object.keys(answer)

	return keys.reduce((acc, key) => {
		const value = answer[key]

		if (typeof value === 'object') {
			const typename = value[2]
			
			const keyArray = key.split('-')
			// 코멘트값이 아니면서 멀티 초이스일경우
			if (!/-comment/.test(key)) {
				if (isMultiChoice.includes(typename) || isRankChoice.includes(typename) || isRankDChoice.includes(typename) || (typename === '_21' && keyArray.length > 1)) {
				
					keyArray.pop()
	
					const newKey = keyArray.join('-')
	
					if (!acc[newKey]) acc[newKey] = { choice: [], comment: {}, typename }
	
					acc[newKey]['choice'].push(value)
	
					return acc
				} 

				// 나머지는 단일선택형이나 매트릭스형태인데 그대로 담으면 된다.
				if (!acc[key]) acc[key] = { choice: [], comment: {}, typename }

				acc[key]['choice'].push(value)
	
				return acc
			}

			
			// 보기마다 코멘트가 있는것
			if (isChoiceComment.includes(typename)) {
				const keyArray = key.split('-')
				const newKey = keyArray[0]
				const commentKey = `${keyArray[0].replace('Q', '')}-${keyArray[1]}`
				const commentIndex = choiceCommentIndex[commentKey]

				if (!acc[newKey]) acc[newKey] = { choice: [], comment: {}, typename }
				
				if (typeof commentIndex !== 'undefined' && value[1]) acc[newKey]['comment'][commentIndex] = value[1]

				return acc
			}

			// 랭킹 보기마다 코멘트가 있는것
			if (isRankComment.includes(typename)) {
				const keyArray = key.split('-')
				const newKey = keyArray[0]
				const survey_question_no = keyArray[0].replace('Q', '')
				const commentRankKey = `${survey_question_no}-M${keyArray[1]}`
				const commentAnswerKey = `${survey_question_no}-A${keyArray[2]}`
				const commentRankIndex = choiceCommentIndex[commentRankKey]
				const commentAnswerIndex = choiceCommentIndex[commentAnswerKey]
				const commentKey = `${commentRankIndex}-${commentAnswerIndex}`

				if (!acc[newKey]) acc[newKey] = { choice: [], comment: {}, typename }
				
				if (typeof commentRankIndex !== 'undefined' && typeof commentAnswerIndex !== 'undefined' && value[1]) acc[newKey]['comment'][commentKey] = value[1]

				return acc
			}
			
			// 문항에 대한 코멘트 (D&D 순위형은 문항이랑 번호가 같기때문에 -comment를 안지운다.)
			const newKey = isRankDChoice.includes(typename) ? key : key.replace('-comment', '')
			const survey_question_no = newKey.replace('Q', '')
			if (!acc[newKey]) acc[newKey] = { choice: [], comment: {}, typename }
			if (value[1]) acc[newKey]['comment'][survey_question_no] = value[1]

			return acc
			
		}		

		if (!acc[key]) acc[key] = { choice: [], comment: {}, typename: key }

		acc[key]['choice'].push(value)

		return acc
	}, {})
}

const changeStandardColumn = (rows: any, index: number) => {
	if (index === 0) return rows

	const keys = Object.keys(rows)
    const firstColumn = keys.splice(index, 1)

    const newColumn = firstColumn.concat(keys)

	return newColumn.reduce((acc, key) => {
		acc[key] = rows[key]
		return acc
	}, {})
}

const getSrcKey = (props: { typename: string; keyValue: string; i: number; index: number }) => {
    const { typename, keyValue, i, index } = props

    if (isImageQuestion.includes(typename)) {
        // 코멘트 답변이 아닐때만 일때
        if (!/-comment/.test(keyValue)) {
            
            let key = ''

            switch (typename) {
                case '_12': {
                    key = `${keyValue.replace('Q', '')}-${index}`
                    break
                }
                case '_13': {
                    key = `${keyValue.replace('Q', '').split('-').shift()}-${index}`
                    break
                }
                case '_18': {
                    key = `${keyValue.replace('Q', '')}-${(i + 1)}-${index}`
                    break
                }
                default:
                    break
            }

            return key
        }       
    }

    return ''
}

const Component = (props) => {
	const { answer, checked, files, imgRows, standardColumnIndex, choiceCommentIndex, expand, step, selectedLaunch, fileQuestionTitle, onSelectClick, onPopoverImageOpen } = props

	const dispatch = useDispatch()
	
	const questionFile = getKeyOfArray('survey_question_no', files)

	const newAnswer = changeStandardColumn(getAnswer(answer, choiceCommentIndex), standardColumnIndex)

	const id = Number(answer['id'])

	const classes = useStyles({ expand, selectedLaunch })

	const handleClick = (key, value) => {
		if (selectedLaunch) return

		dispatch({ type: CHANGEPERSONAL, answer: value, files: questionFile })
	}

    return (
    	<>	
			<TableCell padding="checkbox" className={`${classes.checkbox} ${classes.tableCell}`} onClick={() => onSelectClick(id)}>
            {
                checked ? (
                    <CheckBoxIcon color="secondary" />
                ) : (
                    <CheckBoxOutlineBlankIcon color="action" />
                )
            }
        	</TableCell>
	  		{
		   		Object.keys(newAnswer).map((key, i) => {
					// 진행 상태가 완료가 아니라면 완료시각은 안보여준다.
					if (Number(step) !== Step.End && key === 'einterval') return null

					const value = newAnswer[key]

					const { choice, comment } = value
			
					const typename = typeof choice[0] === 'object' ? choice[0][2] : ''
					
					let className = classes.tableCell
					let classShort = classes.textShort
					let classBox =  classes.box
					let classComment =  classes.comment

					if (i === 0) {
						className = `${classes.id} ${className}`
						classShort = classes.textShortFirst
						classBox =  classes.boxFirst
						classComment = classes.commentFirst
					}

					// 코멘트
					let commentValue = (choice.length === 0 && Object.keys(comment).length === 1) ? comment[key.replace('Q', '')] : ''

					commentValue = commentValue ? commentValue.trim() : ''

					
					return (
						<TableCell key={key} className={className}>
						{
							i === 0 && (
								<span className={`${classes.launch} launch`} onClick={() => handleClick(key, newAnswer)}>
									<LaunchIcon fontSize="small" color="action"  />
								</span>
							)
						}
						{
							(typename === '_19' && !/-comment/.test(key)) ? (
								<ItemValueFileComponent classShort={classShort} classBox={classBox} classComment={classComment} keyValue={key} questionFile={questionFile} fileQuestionTitle={fileQuestionTitle} commentValue={comment[key.replace('Q', '')]} />
							) : (choice.length === 0 && Object.keys(comment).length === 1) ? (
								<>	
								{
									commentValue && (
										<span className={classBox}>
											<span className={classShort}>{commentValue}</span>
										</span>
									)
								}
								</>
							) : (
								<>
								{
									(typeof choice[0] !== 'object') ? choice[0] : (
										<>
										{
											choice.map((c, i) => {
											
												const srcKey = getSrcKey({ typename, keyValue: key, i, index: c[0] })

												const src = imgRows[srcKey]
												const label = c[1]
								
												// 코멘트 값
												let commentValue = '' 
												if (isChoiceComment.includes(typename)) {
													// 보기 indexs에서 값을 찾아온다
													commentValue = comment[c[0]]
												} else if (isRankComment.includes(typename)) {
													// 순위 index - 답변 index
													commentValue = comment[`${(i + 1)}-${c[0]}`]
												} else {
													// 문항 + 코멘트 일때
													commentValue = comment[key.replace('Q', '')]
												}
												
												// 값이 없을 경우
												if (src === '' && label === '' && commentValue === '') return null

												if (src && label !== '') {
													return (
														<span key={`${key}-${i}`} className={classBox}>
															<span className={classes.imgTextBox}>
																<img src={src} alt="listovey" className={classes.img} />
																<span className={classShort}>{label}</span>
															</span>
															{
																commentValue && <span className={`${classComment} ${classShort}`}>{commentValue}</span>
															}
														</span>
													)
												}

												if (label !== '') {
													return (
														<span key={`${key}-${i}`} className={classBox}>
															<span className={classShort}>{label}</span>
															{
																commentValue && <span className={`${classComment} ${classShort}`}>{commentValue}</span>
															}
														</span>
													)
												}

												if (src) {
													return (
														<span key={`${key}-${i}`} className={classBox}>
															<img src={src} alt="listovey" className={classes.img} onClick={() => onPopoverImageOpen(src)} />
															{
																commentValue && <span className={`${classComment} ${classShort}`}>{commentValue}</span>
															}
														</span>
													)
												}

												return null
											})
										}
										</>
									)
								}	
								</>
							)
						}	
						</TableCell>
					)
		   		})
	  		}
  		</>
    )
}

export default withSurveyPopoverImageNew(memo(Component))