import { _19 } from 'gql/survey_analysis_summary'
import DataItemCommentHeaderComponent from './DataItemCommentHeader'
import DataItemFileUploadListComponent from './DataItemFileUploadList'

export interface Props {
    survey_no: number;
    survey_question_no: number;
    filename: string;
    modules: _19['modules'];
    moduleLen: _19['moduleLen'];
}

function DataItemFileUploadComponent(props: Props) {
    const { survey_no, survey_question_no, filename, modules, moduleLen } = props

    return (
        <>
        <DataItemCommentHeaderComponent index={0} commentLen={moduleLen}/>
        <DataItemFileUploadListComponent survey_no={survey_no} id={`${survey_question_no}-file`} filename={filename} modules={modules} moduleLen={moduleLen} />
        </>
    )
}

export default DataItemFileUploadComponent