import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducer'
import VisibleStyled from 'styled/Survey/Analysis/SummaryVisible'
import { LangComponent } from 'component'

interface Props {
    __typename: string;
    summary_question_open: boolean; 
    summary_comment_open: boolean;
    onChangeQuestion: () => void;
    onChangeComment: () => void;
}

function VisibleLangComponent(props: Props) {
    const { __typename, summary_question_open, summary_comment_open, onChangeQuestion, onChangeComment } = props
    const { lang } = useSelector((state: RootState) => state.lang)

    return (
        <LangComponent lang={lang}>
           <VisibleStyled 
            __typename={__typename} 
                summary_question_open={summary_question_open} 
                summary_comment_open={summary_comment_open} 
                onChangeQuestion={onChangeQuestion} 
                onChangeComment={onChangeComment}
            />
        </LangComponent>
    )
}


export default memo(VisibleLangComponent)